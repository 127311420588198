<template>
    <v-app>
        <div>
            <Header/>
        </div>
        <main>
            <div class="categories">  
                <v-card :elevation="1" tile>
                    <v-card-title style="font-weight:bold;margin-left:10px">KATEGORIJE</v-card-title>
                    <v-col
                            v-for="n in this.categories.length"
                            :key="n"
                            class="d-flex child-flex"
                        >
                        <label class="cont">{{categories[n-1].naziv}}
                            <input type="checkbox" @click="toCategory(categories[n-1].ctg_id)">
                            <span class="checkmark"></span>
                        </label>
                    </v-col>
                </v-card>
            </div>
            <div id="main_container">
                <div class="path">
                    <router-link :to="{ name: 'FrontPage' }" style="color: rgb(106, 113, 116); padding-right:5px">
                        Početna
                    </router-link>
                    >
                    <router-link :to="{ name: 'ProductList', params: { categoryId: currentId } }" style="color: rgb(106, 113, 116); padding-left:5px; padding-right:5px">
                        {{this.currentCategory}}
                    </router-link>
                    >
                    <router-link :to="{ name: 'Article', params: { categoryId: currentId, articleId: currentArticle.id } }" style="color: rgb(106, 113, 116); padding-left:5px">
                        {{this.currentArticle.naziv}}
                    </router-link>
                </div>
                <div class="first_part">
                    <div id="picture">
                        <v-card tile class="picture_card">
                            <v-img
                            :src="currentArticle.picture"
                            contain
                            class="image-fit"
                            >
                            </v-img>
                        </v-card>
                    </div>
                    <div id="infos">
                        <div class="article_name">{{this.currentArticle.naziv}}</div>
                        <div class="article_brand">{{this.currentArticle.brand}}</div>
                        <v-btn elevation="2" tile text class="upit" ><a class="link" href="mailto:pecnik@pecnik.hr" target="_blank">POŠALJI UPIT</a></v-btn>
                        <div class="pic-list">
                            <v-row>
                                <!-- v-for="(status, n) in pictures.length"  
                                :src="pictures[n]"-->
                                <v-col
                                    v-for="n in 1"
                                    :key="n"
                                    c
                                >
                                <v-card :elevation="3" tile class="small-images">
                                    <v-img
                                    :src="currentArticle.picture"
                                    class="small-img"
                                    >
                                    </v-img>
                                </v-card>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- <div class="pic-list">
                            <div class="gal-track" itemscope="" itemtype="http://schema.org/ImageGallery" style="transform: translate3d(0px, 0px, 0px);">
                                    <figure class="gal-item" itemprop="associatedMedia" itemscope="" itemtype="http://schema.org/ImageObject" data-gal-index="0">
                                        <a class="gal-item-viewport" itemprop="contentUrl" href="//unikomerc-uvoz.hr/media/image/3295/re-90.jpg" data-width="480" data-height="739" data-medium-image="//unikomerc-uvoz.hr/media/image/3295/re-90.jpg?size=600" title="RE 90, slika 1 velika" data-picture-id="3295">
                                            <img class="gal-item-content" src="//unikomerc-uvoz.hr/media/image/3295/re-90.jpg?size=70" alt="Slika RE 90, slika 1">
                                        </a>
                                    </figure>
                                    <figure class="gal-item gal-current" itemprop="associatedMedia" itemscope="" itemtype="http://schema.org/ImageObject" data-gal-index="1">
                                        <a class="gal-item-viewport" itemprop="contentUrl" href="//unikomerc-uvoz.hr/media/image/3296/re-90.jpg" data-width="480" data-height="739" data-medium-image="//unikomerc-uvoz.hr/media/image/3296/re-90.jpg?size=600" title="RE 90, slika 2 velika" data-picture-id="3296">
                                            <img class="gal-item-content" src="//unikomerc-uvoz.hr/media/image/3296/re-90.jpg?size=70" alt="Slika RE 90, slika 2">
                                        </a>
                                    </figure>
                                    <figure class="gal-item" itemprop="associatedMedia" itemscope="" itemtype="http://schema.org/ImageObject" data-gal-index="2">
                                        <a class="gal-item-viewport" itemprop="contentUrl" href="//unikomerc-uvoz.hr/media/image/3297/re-90.jpg" data-width="479" data-height="479" data-medium-image="//unikomerc-uvoz.hr/media/image/3297/re-90.jpg?size=600" title="RE 90, slika 3 velika" data-picture-id="3297">
                                            <img class="gal-item-content" src="//unikomerc-uvoz.hr/media/image/3297/re-90.jpg?size=70" alt="Slika RE 90, slika 3">
                                        </a>
                                    </figure>
                                    <figure class="gal-item" itemprop="associatedMedia" itemscope="" itemtype="http://schema.org/ImageObject" data-gal-index="3">
                                        <a class="gal-item-viewport" itemprop="contentUrl" href="//unikomerc-uvoz.hr/media/image/3298/re-90.jpg" data-width="479" data-height="479" data-medium-image="//unikomerc-uvoz.hr/media/image/3298/re-90.jpg?size=600" title="RE 90, slika 4 velika" data-picture-id="3298">
                                            <img class="gal-item-content" src="//unikomerc-uvoz.hr/media/image/3298/re-90.jpg?size=70" alt="Slika RE 90, slika 4">
                                        </a>
                                    </figure>
                            </div>
                        </div> -->
			
                    </div>
                </div>
                <div class="second_part">
                    <div v-if="this.currentArticle.brand!='PRIBOR' && this.currentArticle.brand!='USLUGE'" class="second_title">TEHNIČKE ZNAČAJKE</div>
                    <div class="features">
                        <table class="table mt-5">
                            <tbody>
                                <tr v-for="i in titles.length" :key="i">
                                    <td>{{titles[i-1]}}</td>
                                    <td>{{infos[i-1]}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="second_title">OPIS</div>
                    <div class="description">{{this.currentArticle.opis}}</div>  
                </div> 
            </div>
        </main>
        <div>
            <Footer/>
        </div>
    </v-app>
</template>

<script>
    import Header from '@/views/Header';
    import Footer from '@/views/Footer';
    
    export default {
        name: 'App',

        components: {
            Header,
            Footer
        },

        data: () => {
            return {
                categories: [],
                titles: [],
                infos: [],
                ctgry: '',
                currentCategory: '',
                currentId: '',
                currentArticle: ''
            }
        },
        created () {
            this.currentCategory = this.$store.getters.currentCategory(this.$route.params.categoryId).naziv
            this.currentId = this.$store.getters.currentCategory(this.$route.params.categoryId).ctg_id
            this.currentArticle = this.$store.getters.currentArticle(this.$route.params.articleId)
        },
        mounted() {
            this.getCategories()
            this.getArticles()
        },
        methods: {
            getCategories() {
                this.categories = this.$store.state.categories
            },
            getArticles() {
                let i = 0
                var details = this.currentArticle.detalji
                for (var detail in details) {
                    this.titles[i] = detail
                    this.infos[i] = details[detail]
                    i++

                    // var all = entry[category]
                    // for (var name in all) {  
                    //     if (this.name == name) {   
                    //         var info = all[name]
                    //         for (var title in info) {
                    //             if (title == 'brand') {
                    //                 this.brand = info[title]
                    //             }
                    //             if (title == 'picture') {
                    //                 this.picture = info[title]
                    //             }
                    //             if (title == 'opis') {
                    //                 this.description = info[title]
                    //             }
                    //             if (title == 'detalji') {
                    //                 var details = info[title]
                    //                 for (var detail in details) {
                    //                     this.titles[i++] = detail
                    //                     this.infos[j++] = details[detail]
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }
            },
            toCategory(category) {
                this.$router.push({ name: 'ProductList', params: { categoryId: category } })
            }
        }
    }
</script>

<style>
main {
    padding-top: 0.5rem;
}
.categories {
    margin-top: 4rem;
    width: 16%;  
    float: left; 
}
h2 {
    font-size: 50px;
    line-height: 1.2em;
    color: #1f232c;
    letter-spacing: -1px;
    text-align: left;
}
#main_container {
    width: 84%;
    float: left;
    padding-left: 5rem;
    padding-right: 8rem;
}
.first_part {
    width: 100%;
    height: 27rem;
    min-height: 24rem;
    padding-top: 3rem;
}
.second_part {
    width: 100%;
    text-align: left;
    margin-top: 3rem;
}
#picture {
    width: 50%;
    padding-top: 10px;
    padding-left: 10px;
    float: left;
}
#infos {
    width: 40%;
    float: right;
    text-align: left;
}
.article_name {
    padding-top: 1rem;
    font-size: 43px;
    line-height: 1.2em;
    color: #1f232c;
    font-weight: bold;
    font-family: "Gill Sans", sans-serif;
}
.article_brand {
    padding-top:1rem; 
    font-size:23px
}
.upit {
    margin-top:2rem;
    font-weight:bold;
    font-size:16px;
    padding:24px
}
.features {
    margin-left: 10px;
}
.description {
    margin-top: 10px;
    margin-left: 10px;
    width: 100%;
    font-size: 16px;
}
.table {
    width: 65%;
}
.table td{
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
}
.table tr:nth-child(even) {
    background-color: #f2f2f2;
}
.pic-list {
    margin-top: 2rem;
}
.image-fit {
    height: 24rem;
    object-fit: cover;
}
.small-images {
    width: 80px;
}
.small-img {
    cursor: pointer;
}
.second_title {
    font-size:30px;
    font-weight:bold;
    margin-top:4rem
}
/* Customize the label (the container) */
.cont {
    text-transform: uppercase;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-left:10px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.cont:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.cont input:checked ~ .checkmark {
  background-color: rgb(106, 113, 116);;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cont .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} 

.path {
    text-align: left;
    color: rgb(106, 113, 116);
}

@media screen and (max-width: 1100px) {
    main {
        padding-top: 0px;
    }
    .categories {
        top: 0;
        margin-top: 0px;
        width: 100%;  
    }
    #main_container {
        width: 100%;
        padding-left: 1rem;
        padding-right: 2rem;
    }
    .path {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    main {
        padding-top: 0px;
    }
    .categories {
        top: 0;
        margin-top: 0px;
        width: 100%;  
    }
    #main_container {
        width: 100%;
        padding-left: 1rem;
        padding-right: 2rem;
    }
    .path {
        margin-top: 2rem;
    }
    .first_part {
        height: 22rem;
        min-height: 10rem;
        padding-top: 3rem;
    }
    .article_name {
        font-size: 20px;
        line-height: 1.2em;
    }
    .article_brand {
        padding-top:1rem; 
        font-size:14px
    }
    .upit {
        width: 7rem;
        margin-top:1rem;
        padding:8px
    }
    .upit a{
        font-size:11px;
        padding:5px
    }
    .picture_card {
        height: 17rem;
    }
    .image-fit {
        height: 17rem;
        object-fit: cover;
    }
    .second_title {
        font-size:20px;
        margin-top:1.5rem
    }
    .table td{
        padding: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 10px;
    }
    .description {
        font-size: 11px ;
    }
    .small-images {
        width: 60px;
    }
    .pic-list {
        width: 100%;
    }
    .cont {
        margin-bottom: 1px;
    }
}
</style>
